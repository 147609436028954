import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Je suis faible" />
    <h3 className='underline-title'>Je suis faible</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Je n'ai ni glaive ni lance<br />
        Je ne suis pas combattant-frondeur<br />
        Il n'y a aucune pierre dans ma gibeciere<br />
        Dans mon sac de denicheur<br />
        Je n'ai qu'une lance-pierre davidique<br />
        Toi qui démontres que ce n'est pas par l'épée<br />
        <span className="par-starting"></span>ni par la lance<br />
        <span className="par-starting"></span>que tu donnes la victoire<br />
        Toi maitre du combat sans armes<br />
        Livre ces porteurs d'épées à mes mots<br />
        Qu'ils les dardent comme des abeilles<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
